<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="isDataLoadedFromServer"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Add New Product</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <form class="row">
          <!--begin::Body-->
          <div class="col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.customers"
              clearable
              outlined
              dense
              required
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.brand_id"
              label="Brand"
              item-text="text"
              item-value="index"
              :items="serverData.brands"
              clearable
              outlined
              dense
              :error-messages="brand_idErrors"
              @input="$v.formData.brand_id.$touch()"
              @blur="$v.formData.brand_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 col-md-4">
            <v-select
              v-model="formData.category_id"
              label="Category"
              item-text="text"
              item-value="index"
              :items="serverData.categories"
              clearable
              outlined
              dense
              :error-messages="category_idErrors"
              @input="$v.formData.category_id.$touch()"
              @blur="$v.formData.category_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6">
            <v-text-field
              v-model="formData.name"
              label="Name"
              clearable
              outlined
              dense
              :error-messages="nameErrors"
              @input="$v.formData.name.$touch()"
              @blur="$v.formData.name.$touch()"
            ></v-text-field>
          </div>
          <div class="col-12 col-sm-6">
            <v-file-input
              v-model="formData.image"
              show-size
              counter
              multiple
              dense
              outlined
              accept="image/png, image/jpeg, image/bmp"
              prepend-icon="mdi-camera"
              label="Photo"
              clearable
              :error-messages="imageErrors"
              @input="$v.formData.image.$touch()"
              @blur="$v.formData.image.$touch()"
            ></v-file-input>
          </div>
          <div class="col-12">
            <v-text-field
              v-model="formData.description"
              label="Description"
              clearable
              outlined
              dense
            ></v-text-field>
          </div>

          <div class="col-12">
            <sku-datatable
              :setSku="setSkus"
              :skus="formData.skus"
              :skuItem="serverData.skus"
              :clientId="userId"
              :customer="getCustomerData"
            ></sku-datatable>
          </div>
        </form>
        <!--end::Body-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import SkuDatatable from "@/own/components/fulfillment/products/skuContainer/SkuDatatable";
export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      customer_id: { required },
      brand_id: { required },
      category_id: { required },
      name: { required },
      image: { required },
    },
  },
  name: "AddItem",
  components: { SkuDatatable },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      customer_id: null,
      brand_id: null,
      category_id: null,
      name: null,
      description: null,
      image: null,
      skus: [],
    },
  }),
  methods: {
    setSkus(item) {
      this.formData.skus = [...item];
    },
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      ApiService.post("/fulfillment/fulfillment_products/create")
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    toggleModal() {
      if (this.dialog) this.resetCreateForm();
      else this.loadDataFromServer();

      this.dialog = !this.dialog;
    },
    convertToFormData() {
      let data = new FormData();
      let copy = { ...this.formData };
      let skus = this.formData.skus.map((sku) => sku.sku_id);
      copy.skus = skus;

      Object.entries(copy).forEach((item) => {
        if (
          (item[1] && item[0] !== "image" && item[0] !== "photo") ||
          item[1] === 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it, index) => {
              if (typeof it === "object" && !Array.isArray(it) && it !== null) {
                Object.entries(it).forEach((el) => {
                  data.append(`${item[0]}[${index}][${el[0]}]`, el[1]);
                });
              } else {
                data.append(`${item[0]}[]`, it);
              }
            });
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] === "image" || item[0] === "photo")) {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (this.formData.skus.length === 0) {
        Swal.fire({
          title: "Warning",
          html: `<div>At least one sku is required!</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      this.pageLoader(true);
      let data = this.convertToFormData();
      ApiService.post("/fulfillment/fulfillment_products/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: `Record has been successfully added!`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        customer_id: null,
        brand_id: null,
        category_id: null,
        name: null,
        description: null,
        image: null,
        skus: [],
      };
    },
  },
  computed: {
    userId: function () {
      return this.isClient ? false : this.formData.customer_id;
    },
    getCustomerData() {
      return this.formData.customer_id ? this.formData.customer_id : false;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    customer_idErrors() {
      return this.handleFormValidation("customer_id", this);
    },
    brand_idErrors() {
      return this.handleFormValidation("brand_id", this);
    },
    category_idErrors() {
      return this.handleFormValidation("category_id", this);
    },
    nameErrors() {
      return this.handleFormValidation("name", this);
    },
    imageErrors() {
      return this.handleFormValidation("image", this);
    },
  },
};
</script>
